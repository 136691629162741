import React from "react";
import Slider from "react-slick";
import _ from 'lodash';
import {withPrefix} from '../utils';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 5000
};

export default class ImageCarousel extends React.Component {
    render() {
      let section = _.get(this.props, 'section', null);
      return (
        <section id={_.get(section, 'section_id', null)} className={'wrapper alt '+ _.get(section, 'section_id', null)}>
          <div id="image-carousel">
            <Slider {...settings}>
              {_.map(_.get(this.props, 'pageContext.frontmatter.images', null), (img, img_idx) => (
              <div key={img_idx} className="post-image">
                {_.get(img, 'url', null) && (
                <img src={withPrefix(_.get(img, 'url', null))} alt={_.get(img, 'name', null)}/>
                )}
              </div>
              ))}
              {_.map(_.get(this.props, 'pageContext.frontmatter.footer_images', null), (img, img_idx) => (
              <div key={img_idx} className="post-image">
                {_.get(img, 'url', null) && (
                <img src={withPrefix(_.get(img, 'url', null))} alt={_.get(img, 'name', null)}/>
                )}
              </div>
              ))}
            </Slider>
          </div>
        </section>
      );
  }
}